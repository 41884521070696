<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="col-12 q-mb-md right">
        <h4 class="q-ma-none">Account Payable </h4><br />
        <div class="row">
          <div class="row justify q-banner--top-padding">
            <q-btn outline color="green" label="New Payable" @click="accountPayable"></q-btn>
          </div>
        </div>
      </div>
      <div class="col-12 q-mb-md">
        <div class="col-12 q-mb-md">
            <q-table  :dense="$q.screen.lt.md" :grid="$q.screen.xs"
            :data="accountpayable"
            :columns="columns"
            row-key="id" separator="cell"
            no-data-label="I didn't find anything for you!"
            :pagination.sync="pagination"
            :loading="loading"
            :filter="filter"
            @request="onRequest"
            binary-state-sort>
            <template v-slot:body="props">
              <q-tr slot="body" :props="props">
               <q-td key="id" :props="props">{{ props.row.id }}</q-td>
               <q-td key="date_created" :props="props">{{ props.row.date_created }}</q-td>
                <q-td key="vendor" :props="props">{{ props.row.vendor }}</q-td>
                <q-td key="purpose" :props="props">{{ props.row.purpose }}</q-td>
                <q-td key="payment_method" :props="props">{{ props.row.payment_method }}</q-td>
                <q-td key="amount" :props="props">{{ props.row.amount }}</q-td>
                <q-td key="date_prepared" :props="props">{{ props.row.date_prepared }}</q-td>
                <q-td key="cheque_number" :props="props">{{ props.row.cheque_number }}</q-td>
                <q-td key="check_date" :props="props">{{ props.row.check_date }}</q-td>
                <q-td key="remarks" :props="props">{{ props.row.remarks }}</q-td>
                <q-td key="status" :props="props">
                   <q-chip outline v-show=" props.row.status == 'PENDING'" color="warning" text-color="white">
                        {{ props.row.status }}
                   </q-chip>
                   <q-chip outline v-show=" props.row.status == 'DONE'" color="green" text-color="white">
                        {{ props.row.status }}
                   </q-chip>
                </q-td>
                <q-td key="action" :props="props" >
                  <div class="q-gutter-sm" v-show=" props.row.status == 'PENDING'">
                     <q-btn outline class="text-amber-14" size="sm" label="EDIT" @click="editPayable(props.row)"/>
                     <q-btn outline class="text-red-14" size="sm" label="DELETE" @click="deletePayable(props.row)"/>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:no-data="">
              <div class="full-width row flex-center text-red q-gutter-sm">
                <i class="fas fa-exclamation-triangle"></i>
                <span>The filter didn't uncover any results!</span>
              </div>
            </template>
            <template v-slot:loading>
                <q-inner-loading showing>
                    <q-spinner-hourglass  size="50px" color="cyan" />
                </q-inner-loading>
            </template>
            <template v-slot:top-right="props">
               <q-input filled outlined v-model="date_from" mask="date" :rules="['date']" label="From Date">
                  <template v-slot:append>
                     <i class="far fa-calendar-alt cursor-pointer" >
                        <q-popup-proxy ref="fromDateProxy" transition-show="scale" transition-hide="scale">
                           <q-date v-model="date_from" @input="() => $refs.fromDateProxy.hide()" />
                        </q-popup-proxy>
                     </i>
                   </template>
               </q-input>
               <q-input filled outlined v-model="date_to" mask="date" :rules="['date']" label="To Date">
                  <template v-slot:append>
                     <i class="far fa-calendar-alt cursor-pointer" >
                        <q-popup-proxy ref="toDateProxy" transition-show="scale" transition-hide="scale">
                           <q-date v-model="date_to" @input="() => $refs.toDateProxy.hide()" />
                        </q-popup-proxy>
                     </i>
                   </template>
               </q-input>
              <q-btn outline color="orange" size="lg" label="Filter" @click="filterAccountPayable"></q-btn>
            </template>
            <template v-slot:top-left="props">
              <q-input dense v-model="filter" placeholder="Search">
                <i class="fas fa-search" slot="append"></i>
              </q-input>
            </template>
          </q-table>
        </div>
        <NewPayableDialog></NewPayableDialog>
        <PayableDeleteDialog></PayableDeleteDialog>
        <EditPayableDialog></EditPayableDialog>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import NewPayableDialog from './NewPayableDialog';
import PayableDeleteDialog from './PayableDeleteDialog';
import EditPayableDialog from './EditPayableDialog';
export default {
  name: 'AccountPayable',
  components: {
    "NewPayableDialog": NewPayableDialog,
    "PayableDeleteDialog": PayableDeleteDialog,
    "EditPayableDialog": EditPayableDialog,
  },
  data() {
    return {
        filter: '',
        loading: false,
        pagination: {
            page: 1,
            rowsPerPage: 15,
            rowsNumber: 10
        },

      importfile: false,
      isHold:false,
      columns: [
      { name: 'id', align: 'center', label: 'ID', field: 'tid', sortable: true },
      { name: 'date_created', align: 'center', label: 'Date Created', field: 'date_created', sortable: true },
      { name: 'vendor', align: 'center', label: 'Vendor', field: 'vendor', sortable: true },
      { name: 'purpose', align: 'center', label: 'Purpose', field: 'purpose', sortable: true },
      { name: 'payment_method', align: 'center', label: 'Payment Method', field: 'payment_method', sortable: true },
      { name: 'amount', align: 'center', label: 'Amount', field: 'amount', sortable: true },
      { name: 'date_prepared', align: 'center', label: 'Date Prepared', field: 'date_prepared', sortable: true },
      { name: 'cheque_number', align: 'center', label: 'Cheque Number', field: 'cheque_number', sortable: true },
      { name: 'check_date', align: 'center', label: 'Cheque Date', field: 'check_date', sortable: true },
      { name: 'remarks', align: 'center', label: 'Remarks', field: 'remarks', sortable: true },
      { name: 'status', align: 'center', label: 'Status', field: 'status', sortable: true },
      { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
      ],
      accountpayable: [],
      date_from: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
      date_to: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
    }

  },

  created() {
     this.eventHub.$on('refreshList', this.getAccountPayable);
    this.getAccountPayable()
    this.checkIfHasPendingPayable()
  },


  methods: {
      //start if server side
      onRequest (props) {
        const { page, rowsPerPage } = props.pagination
        const filter = props.filter
        const fromDate = props.fromDate
        const toDate = props.toDate
        this.loading = true
        setTimeout(() => {
          this.getRowsNumberCount(filter,fromDate,toDate).then(total => {
              this.pagination.rowsNumber = total
          })
          const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
          const startRow = (page - 1) * rowsPerPage
          this.fetchFromServer(startRow, fetchCount, filter, fromDate, toDate)
          this.pagination.page = page
          this.pagination.rowsPerPage = rowsPerPage
        }, 1500)
      },

      fetchFromServer (startRow, count, filter,fromDate,toDate) {
          axios.get(this.apiUrl + '/payroll/account/payable', {
              headers: { 'Authorization': `Bearer ${this.token}` },
              params: {
                start: startRow,
                countPerPage: count,
                filter: filter,
                fromDate: fromDate,
                toDate: toDate
              }
          }).then((response) => {
              this.accountpayable = response.data.data
              this.loading = false
          }).catch((error) => {
              console.log(error);
          })
     },

      async getRowsNumberCount (filter,from_Date,to_Date) {
         let response = await axios.get(this.apiUrl + '/payroll/account/payable/count', {
             headers: { 'Authorization': `Bearer ${this.token}` },
             params: {
                filter: filter,
                fromDate: from_Date,
                toDate: to_Date
             }
         })
         return response.data
      },

      ///end of server side

      accountPayable: function(){
         this.eventHub.$emit('accountPayable', { status: true });
      },

      donePayable: function(obj){
         this.eventHub.$emit('donePayable', { status: true,obj: obj});
      },

      getAccountPayable: function() {
         this.onRequest({pagination:this.pagination,filter:'',fromDate: this.date_from,toDate:this.date_to})
      },

      exportAccountPayable: function() {
         var exportUrl = this.apiUrl + "/payroll/account/payable/export?fromDate="+this.date_from+"&toDate="+this.date_to+"&filter="+this.filter;
         window.location.href= exportUrl;
      },

      filterAccountPayable:function(){
         this.getAccountPayable();
      },

      checkIfHasPendingPayable: function() {
         axios.get(this.apiUrl + '/payroll/account/payable/checker', {
             headers: { 'Authorization': `Bearer ${this.token}` },
         }).then((response) => {
            console.log(response.data)
         }).catch((error) => {
             console.log(error);
         })
      },

      deletePayable: function(obj){
         this.eventHub.$emit('deletePayable', { status: true,obj: obj});
      },

      editPayable: function(obj){
         this.eventHub.$emit('editPayable', { status: true,obj: obj});
      },

}
}
</script>
<style lang="css" scoped>
</style>
