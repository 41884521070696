<template>
    <q-dialog v-model="doneDialog" persistent transition-show="scale">
      	<q-card style="width: 400px">
        	<q-separator />
        	<q-card-section>
				<div class="q-pa-md" >
					<q-form @submit="payableDone">
                  <q-input filled v-model="done_remarks" label="Remarks" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
				  	<div>
                  <q-btn label="Close" type="reset" color="primary" flat class="q-ml-sm" v-close-popup/>
						<q-btn label="Submit" type="submit" flat color="primary"/>
				  	</div>
					</q-form>
				</div>

            <q-dialog v-model="spinner" persistent >
        		<q-card class="my-card transparent no-shadow">
        			<q-card-section >
        				<q-spinner-gears color="cyan"  size="5.5em"/>
        			</q-card-section>
        		</q-card>
        	</q-dialog>
			</q-card-section>
        	<q-separator />
      	</q-card>
    </q-dialog>

</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

  data() {
    return {
      spinner:false,
      doneDialog:false,
      id:'',
      remarks:'',
      done_remarks:'',
    }
  },

  created(){
    this.eventHub.$on('donePayable', this.donePayable);
  },

  methods: {
   donePayable:function(obj){
      this.id = obj.obj.id
      this.remarks = obj.obj.remarks
      this.doneDialog = obj.status
   },

   payableDone:function(){
         this.spinner = true;
         var remarks = this.remarks+' | '+this.done_remarks
        let formData  = new FormData();
        formData.append('id',this.id);
        formData.append('remarks',remarks);
        axios.create({
         baseURL : this.apiUrl,
         headers : {'Authorization' : `Bearer ${this.token}`}
      }).post(`/payroll/account/payable/done`,formData)
       .then((response) => {
         this.spinner = false;
            this.$q.notify({
               message: response.data.message,
               color: 'green',
               position:'center',
               actions: [
            { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
            ]
         })
         this.doneDialog = false
         this.remarks = ''
         this.eventHub.$emit('refreshList');
       }).catch((error) => {
         var message = error.response.data.exception[1].message
         this.$q.notify({
             message: message,
             color: 'negative',
             position:'center',
             actions: [
         { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
         ]
      })
         this.spinner = false
         this.doneDialog = false
      });
     },
   }
}
</script>
<style lang="css" scoped>
</style>
