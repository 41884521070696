<template>
    <q-dialog v-model="addDialog" persistent transition-show="scale">
      	<q-card style="width: 400px">
        	<q-card-section>
          		<div class="text-h6">Account Payable</div>
        	</q-card-section>
        	<q-separator />
        	<q-card-section>
				<div class="q-pa-md" >
					<q-form @submit="onSubmit">
                  <q-input filled v-model="vendor" label="Vendor" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
					   <q-input filled v-model="purpose" label="Purpose" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
					   <q-select filled v-model="payment_method" @input="checkPaymentMethod" :options="options" label="Payment Method" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
                  <q-input filled type="number" min="0" v-model="amount" label="Amount" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
                  <q-input filled outlined v-model="date_prepared" mask="date" :rules="['date']" label="Date Prepared">
                     <template v-slot:append>
                        <i class="far fa-calendar-alt cursor-pointer" >
                           <q-popup-proxy ref="fromDateProxy" transition-show="scale" transition-hide="scale">
                              <q-date v-model="date_prepared" @input="() => $refs.fromDateProxy.hide()" />
                           </q-popup-proxy>
                        </i>
                      </template>
                  </q-input>
                  <q-input filled v-show="isCheck" v-model="check_number" label="Cheque Number" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
                  <q-input filled v-show="isCheck" outlined v-model="check_date" mask="date" :rules="['date']" label="Cheque Date">
                     <template v-slot:append>
                        <i class="far fa-calendar-alt cursor-pointer" >
                           <q-popup-proxy ref="toDateProxy" transition-show="scale" transition-hide="scale">
                              <q-date v-model="check_date" @input="() => $refs.toDateProxy.hide()" />
                           </q-popup-proxy>
                        </i>
                      </template>
                  </q-input>
                  <q-input filled v-model="remarks" label="Remarks" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
				  	<div align="right">
                  <q-btn label="Close" type="reset" color="primary" flat class="q-ml-sm" v-close-popup/>
						<q-btn label="Submit" type="submit" flat color="primary"/>
				  	</div>
					</q-form>
				</div>

            <q-dialog v-model="spinner" persistent >
        		<q-card class="my-card transparent no-shadow">
        			<q-card-section >
        				<q-spinner-gears color="cyan"  size="5.5em"/>
        			</q-card-section>
        		</q-card>
        	</q-dialog>
			</q-card-section>
        	<q-separator />
      	</q-card>
    </q-dialog>

</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

  data() {
    return {
      isCheck:false,
      options: ['CASH', 'FUND TRANSFER', 'CHECK'],
      spinner:false,
      addDialog:false,
      vendor:'',
      purpose:'',
      payment_method:'CASH',
      amount:'',
      date_prepared: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
      check_number:'N/A',
      check_date: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
      remarks:'',
    }
  },

  created(){
    this.eventHub.$on('accountPayable', this.accountPayable);
  },

  methods: {
   accountPayable:function(obj){
      this.addDialog = obj.status
   },

   onSubmit:function(){
         this.spinner = true;
        let formData  = new FormData();
        formData.append('vendor',this.vendor);
        formData.append('purpose',this.purpose);
        formData.append('payment_method',this.payment_method);
        formData.append('amount',this.amount);
        formData.append('date_prepared',this.date_prepared);
        formData.append('cheque_number',this.check_number);
        formData.append('check_date',this.check_date);
        formData.append('remarks',this.remarks);
        axios.create({
         baseURL : this.apiUrl,
         headers : {'Authorization' : `Bearer ${this.token}`}
      }).post(`/payroll/account/payable`,formData)
       .then((response) => {
         this.spinner = false;
            this.$q.notify({
               message: response.data.message,
               color: 'green',
               position:'center',
               actions: [
            { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
            ]
         })
         this.addDialog = false
         this.vendor = ''
         this.purpose = ''
         this.payment_method = ''
         this.amount = ''
         this.check_number = ''
         this.remarks = ''
         this.eventHub.$emit('refreshList');
       }).catch((error) => {
         var message = error.response.data.exception[1].message
         this.$q.notify({
             message: message,
             color: 'negative',
             position:'center',
             actions: [
         { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
         ]
      })
         this.spinner = false
      });
     },

     checkPaymentMethod:function(){
        if(this.payment_method == 'CHECK'){
           this.isCheck = true;
           this.check_number = ''
        }else{
           this.isCheck = false;
           this.check_number = 'N/A'
        }
     }

   }
}
</script>
<style lang="css" scoped>
</style>
